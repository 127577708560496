:root {
  --safe-area-inset-bottom: calc(100vh - var(--tg-viewport-stable-height, 100vh))
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222;
  /*font: 20px Verdana,Arial,sans-serif;*/
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button {
  /*font: 18px Verdana,Arial,sans-serif;*/
  color: white;
  border: 0;
}
.page {
  /*padding-bottom: var(--safe-area-inset-bottom);*/
}
.action-button {
  padding: 10px 15px;
}

.period-button {
  padding: 10px 15px;
  border-radius: 8px;
  margin-right: 3px;
  color: white;
}
.white-12 {
  background-color: #ffffff1f;
}
.white-0 {
  background-color: #ffffff00;
}
.blue {
  background-color: #07d;
}
.yellow {
  background-color: #ffcd33;
}
.white {
  background-color: #ffffff;
}
.text-white {
  color: #ffffff;
}
.text-black {
  color: #000000;
}
.cursor-pointer {
  cursor: pointer;
}
.opacity-05 {
  opacity: 0.5;
}
.opacity-1 {
  opacity: 1;
}

.chart-section {

}

@media (max-width: 400px) {
  .chart-section {
    font-size: .7em;
  }
}
.chart-link-button {
  margin: 0;
  background-color: #ffffff1f;
  padding: 5px;
  border-radius: 50%;
  display: inline-flex;
}
.chart-period-button {
  width: 18%;
  padding: 10px 0;
}
.title-secondary {
  font-size: .8em;
}
.section-title {
  color: #ccc;
}
.page-container {
  padding: 20px 20px 100px;
}
.title {
  color: #ccc;
  font-size: 32px;
}

.red {
  color: #f66;
}

.green {
  color: #00CB00FF;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.content-settings-section-earn-text {
  font-size: 14px;
}
.button-decoration-underline {
  text-decoration: underline;
}
.button-decoration-underline:hover {
  text-decoration: none;
}

/* EARN*/
.earn-item {
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.earn-item-description {
  font-size: 12px;
  color: #8d8d8d;
}
.earn-item:hover {
  cursor: pointer;
}
.earn-item-button {
  color: black;
  border-radius: 15px;
  background-color: white;
  padding: 0 7px;
}
